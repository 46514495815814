<template>
  <div class="explore_decide_invest">
    <div class="explore_decide_invest-content">
      <h4>Explore. <span class="text-green">Decide</span>. Invest</h4>
      <p ref="explore_decide_invest_desc_1" class="explore_decide_invest_desc_1">
        Real Estate ownership simplified into asset-backed security tokens whose
        fractional values are tied to the real-life appreciation value of the
        property tokenized.
      </p>
      <p ref="explore_decide_invest_desc_2" class="explore_decide_invest_desc_2">
        Owning a diversified property portfolio can't be any easier. Viable
        investment opportunities with unmatched returns brought to your
        fingertips.
      </p>
      <button @click="$router.push({ name: 'ExploreMarketPlace' })">
        <span>Start Investing </span>
        <i class="bx bxs-chevron-right"></i>
      </button>
    </div>
    <div ref="explore_decide_invest_image" class="explore_decide_invest-img explore_decide_invest_image">
      <img src="@/assets/img/housee.png" alt="" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const explore_decide_invest_desc_1 = ref(null)
    const explore_decide_invest_desc_2 = ref(null)
    const explore_decide_invest_image = ref(null)

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('explore_decide_invest_desc_1') || entry.target.classList.contains('explore_decide_invest_desc_2')) {
          entry.target.classList.add('zoom-in')
          observer.unobserve(entry.target)
        } else if (entry.target.classList.contains('explore_decide_invest_image')) {
          entry.target.classList.add('slide-in-left')
          observer.unobserve(entry.target)
        }
      }
    }

    const explore_decide_invest_desc_1_observer = new IntersectionObserver(handle_intersection)
    const explore_decide_invest_desc_2_observer = new IntersectionObserver(handle_intersection)
    const explore_decide_invest_image_observer = new IntersectionObserver(handle_intersection)
    return {
      explore_decide_invest_desc_1,
      explore_decide_invest_desc_2,
      explore_decide_invest_image,

      explore_decide_invest_desc_1_observer,
      explore_decide_invest_desc_2_observer,
      explore_decide_invest_image_observer 
    }
  },
  mounted() {
    this.explore_decide_invest_desc_1_observer.observe(this.explore_decide_invest_desc_1)
    this.explore_decide_invest_desc_2_observer.observe(this.explore_decide_invest_desc_2)
    this.explore_decide_invest_image_observer.observe(this.explore_decide_invest_image)
  }
};
</script>

<style scoped>
.explore_decide_invest {
  background: var(--mainLightGreenColor);
  width: 100%;
  /* max-height: 600px; */
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  overflow: hidden;
  transition: all;
  padding: 80px 100px;
}

.explore_decide_invest-content {
  width: 45%;
  margin-right: auto;
}

.explore_decide_invest h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 61px;
  color: #000000;
  margin-bottom: 30px;
  margin-top: -30px;
}

.explore_decide_invest p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 180.62%;
  color: #363636;
  margin-bottom: 50px;
}

.explore_decide_invest img {
  width: 90%;
  /* height: auto; */
  border-radius: 2px;
  margin-top: 30px;
}

.explore_decide_invest-content button {
  background: transparent;
  border: 2px solid var(--mainGreenColor);
  border-radius: 5px;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  transition: all 350ms ease-in-out;
}

.explore_decide_invest-content button i {
  font-size: 16px;
}

.explore_decide_invest-content button:hover {
  background: var(--mainGreenColor);
  color: #fff;
}

@media screen and (max-width: 1400px) {
  .explore_decide_invest {
    /* max-height: 600px; */
    padding: 80px;
  }

  .explore_decide_invest-content {
    width: 42%;
    margin-right: 60px;
  }
}
@media screen and (max-width: 1150px) {
  .explore_decide_invest-content {
    width: 52%;
    margin-right: 60px;
  }
  .explore_decide_invest {
    padding: 80px 60px;
  }

  .explore_decide_invest h4 {
    font-size: 38px;
    /* margin-bottom: 60px; */
  }

  .explore_decide_invest p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .explore_decide_invest-content {
    width: 75%;
    margin-right: 60px;
  }

  .explore_decide_invest h4 {
    font-size: 35px;
    line-height: 41px;
  }
}
@media screen and (max-width: 900px) {
  .explore_decide_invest {
    padding: 60px 35px;
  }

  .explore_decide_invest-content {
    width: 95%;
    margin-right: 60px;
  }

  .explore_decide_invest h4 {
    font-size: 30px;
  }
}

@media screen and (max-width: 780px) {
  .explore_decide_invest {
    padding: 80px 25px 80px;
    display: block;
    /* min-height: 300px; */
  }

  .explore_decide_invest-content {
    width: 100%;
    margin-right: 0px;
  }
  .explore_decide_invest-img {
    display: none;
  }

  .explore_decide_invest h4 {
    font-size: 25px;
    /* margin-bottom: 60px; */
  }
}

@media screen and (max-width: 700px) {
  /* .explore_decide_invest-content {
    width: 80%;
    margin: auto;
    text-align: center;
  } */
}

@media screen and (max-width: 580px) {
  .explore_decide_invest-content {
    width: 97%;
    margin: auto;
  }

  .explore_decide_invest h4 {
    font-size: 27px;
  }
}
@media screen and (max-width: 480px) {
  .explore_decide_invest {
    padding: 70px 20px 30px;
  }
  .explore_decide_invest h4 {
    font-size: 25px;
    margin-bottom: 30px;
  }

  .explore_decide_invest p {
    font-size: 16px;
  }

  .explore_decide_invest-content button {
    padding: 11px 40px;
    font-size: 13px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 380px) {
  .explore_decide_invest-content {
    width: 98%;
    margin: auto;
  }
  .explore_decide_invest h4 {
    font-size: 23px;
  }
}
</style>
