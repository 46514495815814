<template>
  <div>
    <LoginModal />
    <AlertPopup />
    <VerifyPinCodeModal />
    <PageLoading />
  </div>
</template>

<script>
import VerifyPinCodeModal from "./VerifyPinCodeModal.vue";
import LoginModal from "./LoginModal.vue";
import AlertPopup from "./AlertPopup.vue";
import PageLoading from "../loading/PageLoading.vue";
export default {
  components: {
    LoginModal,
    VerifyPinCodeModal,
    AlertPopup,
    PageLoading,
  },
};
</script>

<style></style>
