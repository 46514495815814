<template>
  <div class="trackWithdraw">
    <div class="trackWithdraw_wrapper_content">
      <h4>
        Track & Withdraw Your <span class="text-green">Income</span> From Day
        One
      </h4>
      <p ref="track_withdraw_desc" class="track_withdraw_desc">
        From your first investment, you will start to see your dividends
        increase in your portfolio and will be able to cash out instantly.
      </p>
      <div class="product_more_button text-center">
        <button @click="$router.push({ name: 'ExploreMarketPlace' })">
          <span>Start Investing </span>
          <i class="bx bxs-chevron-right"></i>
        </button>
      </div>
    </div>
    <div ref="track_withdraw_image" class="trackWithdraw-image track_withdraw_image">
      <img src="@/assets/img/hand-image.png" alt="" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const track_withdraw_desc = ref(null)
    const track_withdraw_image = ref(null)
   

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('track_withdraw_desc')) {
          entry.target.classList.add('zoom-in')
          observer.unobserve(entry.target)
        } else if (entry.target.classList.contains('track_withdraw_image')) {
          entry.target.children[0].classList.add('slide-in-up-left')
          observer.unobserve(entry.target)
        }
      }
    }

    const track_withdraw_desc_observer = new IntersectionObserver(handle_intersection)
    const track_withdraw_image_observer = new IntersectionObserver(handle_intersection)

    return {
      track_withdraw_desc,
      track_withdraw_image,

      track_withdraw_desc_observer,
      track_withdraw_image_observer
    }
  },
  mounted() {
    this.track_withdraw_desc_observer.observe(this.track_withdraw_desc)
    this.track_withdraw_image_observer.observe(this.track_withdraw_image)
  }
};
</script>

<style scoped>
.trackWithdraw {
  width: 100%;
  background-color: rgba(255, 174, 27, 0.1);
  background-image: url("../../../assets/img/bacc.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 820px;
  padding: 150px 0px 0px 80px;
  display: grid;
  grid: 100% / auto auto;
  /* background-attachment: fixed; */
}

.trackWithdraw_wrapper_content {
  width: 550px;
  overflow: hidden;
}

.trackWithdraw_wrapper_content h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 65px;
  color: #000000;
  margin-bottom: 40px;
}

.trackWithdraw_wrapper_content p {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 39px;
  color: #000000;
}

/* img {
  width: 280px;
} */

.product_more_button button {
  background: #fff;
  border: 2px solid var(--mainGreenColor);
  border-radius: 5px;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  transition: all 350ms ease-in-out;
  margin-top: 50px;
}

.product_more_button button i {
  font-size: 16px;
}

.product_more_button button:hover {
  background: var(--mainGreenColor);
  color: #fff;
}

.trackWithdraw-image {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: right;
}
.trackWithdraw .trackWithdraw-image img {
  display: block;
  width: 80%;
}
/* .trackWithdraw .trackWithdraw-image {
  display: none;
} */

@media screen and (max-width: 1350px) {
  .trackWithdraw_wrapper_content h4 {
    font-size: 42px;
  }
}

@media screen and (max-width: 1150px) {
  .trackWithdraw {
    height: 630px;
    padding: 150px 0px 0px 40px;
  }
  .trackWithdraw_wrapper_content {
    width: 590px;
  }

  .trackWithdraw_wrapper_content h4 {
    font-size: 40px;
    line-height: 61px;
  }
  img {
    width: 240px;
  }
}

@media screen and (max-width: 1000px) {
  .trackWithdraw_wrapper_content {
    width: 500px;
  }

  .trackWithdraw_wrapper_content h4 {
    font-size: 38px;
    line-height: 51px;
  }

  .trackWithdraw_wrapper_content p {
    font-size: 15px;
  }
}

@media screen and (max-width: 850px) {
  .trackWithdraw {
    height: 630px;
    padding: 100px 0px 0px 40px;
    /* grid: auto / auto; */
    /* background: rgba(255, 174, 27, 0.1); */
  }
  .trackWithdraw_wrapper_content {
    width: 300px;
  }

  .trackWithdraw_wrapper_content h4 {
    font-size: 30px;
    line-height: 51px;
  }

  .trackWithdraw .trackWithdraw-image img {
    width: 80%;
  }
}
@media screen and (max-width: 750px) {
  .trackWithdraw {
    height: auto;
    padding: 100px 0px 0px;
    grid: auto / auto;
    gap: 30px 0px
  }
  .trackWithdraw_wrapper_content {
    width: 100%;
    padding: 10px 40px;
    text-align: center;
  }

  .trackWithdraw_wrapper_content h4 {
    font-size: 30px;
    line-height: 51px;
  }

  .trackWithdraw_wrapper_content p {
    font-size: 15px;
  }

  .product_more_button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product_more_button button {
    margin-top: 30px;
  }

  /* .trackWithdraw .trackWithdraw-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 100px;
  } */
  .trackWithdraw .trackWithdraw-image img {
    width: 70%;
  }
}
@media screen and (max-width: 520px) {
  .trackWithdraw_wrapper_content h4 {
    line-height: 41px;
  }

  .trackWithdraw .trackWithdraw-image img {
    width: 70%;
  }
}
@media screen and (max-width: 450px) {
  .trackWithdraw {
    padding: 50px 0px 0px;
    grid: auto;
    gap: 30px 0px
  }
  .trackWithdraw_wrapper_content h4 {
    font-size: 25px;
  }
  .trackWithdraw_wrapper_content {
    padding: 10px 20px;
  }
  .trackWithdraw_wrapper_content p {
    font-size: 15px;
    line-height: 30px;
  }
}
</style>
