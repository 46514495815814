<template>
  <div ref="step_1" class="step">
    <div>
      <div class="circle">1</div>
    </div>
    <div>
      <div class="title">Deposit/withdraw using supported cryptocurrencies</div>
      <!-- <div class="caption">
        Using your phone with the app verify you will scan your id card or
        passport and then use the frontal camera to match it with your own face.
        This ensures that you are really you.
      </div> -->
    </div>
  </div>
  <div ref="step_2" class="step">
    <!-- step-active -->
    <div>
      <div class="circle">2</div>
    </div>
    <div>
      <div class="title">Explore and buy into choice securitized property</div>
    </div>
  </div>
  <div ref="step_3" class="step">
    <div>
      <div class="circle">3</div>
    </div>
    <div>
      <div class="title">Easily sell or swap your investments</div>
    </div>
  </div>
  <div ref="step_4" class="step">
    <div>
      <div class="circle">4</div>
    </div>
    <div>
      <div class="title">Manage all your assets from one dashboard</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const step_1 = ref(null)
    const step_2 = ref(null)
    const step_3 = ref(null)
    const step_4 = ref(null)

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if (['step_1', 'step_2', 'step_3', 'step_4'].includes(entry.target.__vnode.props.ref)) {
          entry.target.classList.add('slide-in-right')
          observer.unobserve(entry.target)
        }
      }
    }

    const step_1_observer = new IntersectionObserver(handle_intersection)
    const step_2_observer = new IntersectionObserver(handle_intersection)
    const step_3_observer = new IntersectionObserver(handle_intersection)
    const step_4_observer = new IntersectionObserver(handle_intersection)

    return {
      step_1,
      step_2,
      step_3,
      step_4,

      step_1_observer,
      step_2_observer,
      step_3_observer,
      step_4_observer
    }
  },
  mounted() {
    this.step_1_observer.observe(this.step_1)
    this.step_2_observer.observe(this.step_2)
    this.step_3_observer.observe(this.step_3)
    this.step_4_observer.observe(this.step_4)
  }
};
</script>

<style scoped>
/* -------------------------------------------------------------------------
  VERTICAL STEPPERS
-------------------------------------------------------------------------- */

/* Steps */
.step {
  position: relative;
  min-height: 5.6em;
  color: grey;
}

/* .step + .step {
  margin-top: 1.2em;
} */

.step > div:first-child {
  position: static;
  height: 0;
}

.step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}

.step.step-active {
  color: #4285f4;
}

.step.step-active .circle {
  background-color: #000000;
}

/* Circle */
.circle {
  background: #000000;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
}

/* Vertical Line */
.circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: 0px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100% !important;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -150%;
  background-color: #000000;
  /* border-style: dashed; */
  z-index: -1;
}

.step:last-child .circle:after {
  display: none;
}

.step:first-child .circle:after {
  height: 100%;
  z-index: -1;
}

/* Stepper Titles */
.title {
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  color: #2d2d2d;
  /* padding-bottom: 2.3em; */
}
.caption {
  font-size: 0.8em;
}

@media screen and (max-width: 550px) {
  .title {
    font-size: 15px;
  }
}
</style>
