<template>
  <div class="manageAssets">
    <h4>
      Exchange Your Crypto For Liquid Property
      <span class="text-green">Ownership</span>
    </h4>
    <div class="manageAssets_grid">
      <div class="manageAssets_grid_item">
        <VerticalSteppers />
      </div>
      <div ref="manage_assets_image" class="manageAssets_grid_item manage_assets_image">
        <img src="@/assets/img/large-small-site.png" alt="" />
      </div>
    </div>
    <div class="product_more_button text-center">
      <button
        @click="$router.push({ name: 'ExploreMarketPlace' })">
        <span>Start Investing </span>
        <i class="bx bxs-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import VerticalSteppers from "./VerticalSteppers.vue";
export default {
  components: {
    VerticalSteppers,
  },
  setup() {

    const manage_assets_image = ref(null)

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if(entry.target.classList.contains('manage_assets_image')) {
          entry.target.classList.add('shake')
          observer.unobserve(entry.target)
        }
      }
    }

    const manage_assets_image_observer = new IntersectionObserver(handle_intersection)

    return {
      manage_assets_image,
      manage_assets_image_observer,
    }
  },
  mounted() {
    this.manage_assets_image_observer.observe(this.manage_assets_image)
  }
};
</script>

<style scoped>
.manageAssets {
  padding: 80px 100px;
  background: rgba(24, 133, 111, 0.05);
  overflow: hidden;
}

.manageAssets h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 71px;
  color: #000000;
  margin-bottom: 30px;
  width: 700px;
}

.manageAssets_grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manageAssets_grid .manageAssets_grid_item:first-child {
  width: 46%;
  margin-right: 90px;
}

.product_more_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.product_more_button button {
  background: transparent;
  border: 2px solid var(--mainGreenColor);
  border-radius: 5px;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  transition: all 350ms ease-in-out;
}

.product_more_button button i {
  font-size: 16px;
}

.product_more_button button:hover {
  background: var(--mainGreenColor);
  color: #fff;
}

@media screen and (max-width: 1320px) {
  .manageAssets {
    padding: 80px 100px;
  }
  .manageAssets_grid .manageAssets_grid_item:first-child {
    width: 55%;
    margin-right: 90px;
  }
}
@media screen and (max-width: 1200px) {
  .manageAssets {
    padding: 80px 60px;
  }

  .manageAssets h4 {
    font-size: 40px;
  }

  .manageAssets_grid .manageAssets_grid_item:first-child {
    width: 70%;
    margin-right: 90px;
  }

  .product_more_button {
    margin-top: 40px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 1100px) {
  .manageAssets_grid .manageAssets_grid_item:first-child {
    width: 88%;
    margin-right: 90px;
  }
}
@media screen and (max-width: 1050px) {
  .manageAssets {
    padding: 60px 30px;
  }
  .manageAssets_grid .manageAssets_grid_item:first-child {
    width: 100%;
    margin-right: 0px;
  }

  .manageAssets h4 {
    font-size: 32px;
  }

  .manageAssets_grid .manageAssets_grid_item img {
    width: 80%;
    margin-left: 50px;
  }
}

@media screen and (max-width: 900px) {
  .manageAssets {
    padding: 60px 30px;
  }

  .manageAssets h4 {
    font-size: 26px;
    text-align: center;
    width: 100%;
  }
  .manageAssets_grid .manageAssets_grid_item:first-child {
    margin-right: 50px;
    width: 100%;
  }
}

@media screen and (max-width: 859px) {
  .manageAssets {
    padding: 80px 90px;
  }

  .manageAssets h4 {
    text-align: center;
  }

  .manageAssets_grid {
    display: block;
  }

  .manageAssets_grid .manageAssets_grid_item:first-child {
    width: 55%;
    margin: 50px auto 40px 0px;
  }

  .manageAssets_grid .manageAssets_grid_item img {
    width: 100%;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 650px) {
  .manageAssets {
    padding: 80px 40px;
  }
}

@media screen and (max-width: 550px) {
  .manageAssets {
    padding: 80px 20px;
  }

  .manageAssets h4 {
    font-size: 21px;
    line-height: 33px;
  }

  .manageAssets_grid .manageAssets_grid_item:first-child {
    width: 70%;
    margin: 50px auto 40px 20px;
  }
}

@media screen and (max-width: 430px) {
  .manageAssets {
    padding: 60px 25px;
  }

  .manageAssets h4 {
    font-size: 21px;
  }

  .manageAssets_grid .manageAssets_grid_item:first-child {
    width: 79%;
    margin: 50px auto 40px 10px;
  }

  .manageAssets p {
    font-size: 14px;
  }
}
</style>
