<template>
  <div class="footer">
    <div class="footer_grid">
      <div class="footer_grid_item">
        <h4>relsify</h4>
        <p>
          Relsify offers a secured blockchain-based marketplace for investing in
          high-quality, fractional, and diversified real estate assets,
          tokenized & issued as security tokens.
        </p>
      </div>
      <div class="footer_grid_item">
        <h5>Investor</h5>
        <router-link :to="{ name: 'TokenizeProperty' }"
          >Tokenize your Property</router-link
        >
        <router-link :to="{ name: 'ExploreMarketPlace' }"
          >Explore marketplace</router-link
        >
        <!-- <a href="">Calculate Profitability Rate </a> -->
      </div>
      <!-- <div class="footer_grid_item">
        <h5>Realestate Manager</h5>
        <a href="">Start a Real Estate Career </a>
        <a href="">Property Analysis Tool </a>
        <a href="">Sell Your Property </a>
        <a href="">Finance Your Property </a>
      </div> -->
      <div class="footer_grid_item">
        <h5>Resources</h5>
        <router-link :to="{ name: 'Blog' }">Blog</router-link>
        <!-- <router-link :to="{ name: 'Careers' }">Career</router-link> -->
        <router-link :to="{ name: 'FAQ' }">FAQ</router-link>
        <router-link :to="{ name: 'HelpCenter' }">Help Center</router-link>
      </div>
      <div class="footer_grid_item">
        <h5>Relsify</h5>
        <router-link :to="{ name: 'About' }">About</router-link>
        <router-link :to="{ name: 'Contact' }">Contact</router-link>
        <router-link :to="{ name: 'TermsAndConditions' }"
          >Terms & Conditions</router-link
        >
      </div>
    </div>

    <div class="footer_copyright">
      <p>
        Copyright © {{ getCurrentYear }}
        <router-link :to="{ name: 'Home' }"
          ><span>Relsify.com</span></router-link
        >
      </p>
    </div>
    <div class="footer_license">
      <p>Relsify is a registered company with the Corporate Affairs Commission in Nigeria and the State of Wyoming in the United States. The company provides Africans, both locally and internationally, the opportunity to invest in global real estate for as low as $10.</p>
      <p>The assets offered by Relsify comply with SEC regulations and are managed and held in trust by Stanbic IBTC Trustees and for members only of the PH Relsify Real estate Multipurpose Cooperative under the Cooperative license number RS 34636.</p>
      <p>By using the Relsify platform, you are acknowledging and agreeing to abide by our terms of Service and Privacy Policy. Please note that all investments involve a certain level of risk and may result in partial or total loss. Although we rely on data from third-party reliable sources, we cannot guarantee the accuracy or completeness of such data provided by developers or other third parties.</p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    getCurrentYear() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>

<style scoped>
.footer {
  position: relative;
  background: #ffffff;
  padding: 40px 60px 40px 100px;
  font-family: var(--mainFontFamily);
  margin-top: 40px;
}

.footer_grid {
  display: grid;
  grid-template-columns: 1fr 270px 230px 230px;
  column-gap: 40px;
}

.footer_grid_item {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.footer_grid_item:first-child {
  padding-right: 30px;
  border-right: 1px solid #444;
}

.footer_grid_item h4 {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  text-transform: uppercase;
  color: var(--mainGreenColor);
}

.footer_grid_item h5 {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: var(--mainGreenColor);
}

.footer_grid_item p {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: left;
  color: #111;
  margin-bottom: 0px;
}

.footer_grid_item a {
  font-family: var(--mainFontFamily);
  color: #111;
  font-size: 14px;
}

.footer_grid_item a:hover {
  color: var(--mainGreenColor);
}

.footer_copyright {
  margin-top: 80px;
  text-align: center;
}

.footer_copyright p {
  font-size: 15px;
}

.footer_copyright a {
  color: var(--mainGreenColor);
}

.footer_copyright p span {
  margin-left: 10px;
}

.footer_license {
  padding-top: 45px;
  padding-bottom: 60px;
  padding-left: 2vw;
  padding-right: 2vw;
}
.footer_license p {
  color: #383838;
  font-size: 12px;
  word-wrap: break-word;
  margin-bottom: 18px;
}


@media screen and (max-width: 1400px) {
  .footer {
    padding: 40px 60px 40px 100px;
  }

  .footer_grid {
    display: grid;
    /* grid-template-columns: 1fr auto auto auto auto; */
    column-gap: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    padding: 40px 60px 40px 60px;
  }

  .footer_grid {
    grid-template-columns: 1fr 200px 150px 150px;
  }
}
@media screen and (max-width: 1150px) {
  .footer {
    padding: 40px;
  }

  .footer_grid {
    column-gap: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .footer {
    padding: 40px 22px;
    background: #fff;
  }

  .footer_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer_grid_item {
    margin-bottom: 40px;
  }

  .footer_copyright {
    margin-top: 10px;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    padding: 40px 22px 10px;
    margin-top: 10px;
  }

  .footer_grid {
    display: block;
  }

  .footer_grid_item:first-child {
    border: none;
    padding-right: 0px;
  }

  .footer_copyright {
    margin-top: 10px;
  }
}
</style>
