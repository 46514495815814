<template>
  <div class="product-card" v-if="project">
    <div class="product-card-img">
      <img
        v-if="project.mainImage.optimizedUrl"
        :src="project.mainImage.optimizedUrl"
        alt=""
        style="cursor: pointer"
        @click.self="
          $router.push({
            name: 'ProjectDetails',
            query: { id: project.id },
          })
        "
      />
      <a
        href="javascript:;"
        @click="filterProduct(project.category)"
        class="product-card-category"
        >{{ project.category }}</a
      >
    </div>
    <div class="product-card-description">
      <div class="product-card-description-header">
        <h3
          @click="
            $router.push({
              name: 'ProjectDetails',
              query: { id: project.id },
            })
          "
          style="cursor: pointer"
        >
          {{ project.title }}
        </h3>
        <p>
          {{ project.description.slice(0, 75) }}
          <span
            v-if="project.description.length > 75"
            @click="
              $router.push({
                name: 'ProjectDetails',
                query: { id: project.id },
              })
            "
          >
            ...<b>Read More</b>
          </span>
        </p>
      </div>
      <hr />

      <div class="product-card-description-details">
        <div class="product-card-description-details-item">
          <!-- <p>
            <span class="product-card-description-details-item-title"
              >Coin</span
            >
            <span class="product-card-description-details-item-prc green-color"
              ><img
                v-if="project.coin.iconUrl"
                :src="project.coin.iconUrl"
                alt=""
                style="border-radius: 60px"
              />
              {{ project.coin.name }}</span
            >
          </p> -->
          <p>
            <span class="product-card-description-details-item-title"
              >Country</span
            >
            <span class="product-card-description-details-item-prc green-color"
              ><img
                v-if="countryFlag"
                :src="countryFlag"
                alt=""
                style="
                  margin-right: 2px;
                  border-radius: 100%;
                  width: 14px;
                  height: 14px;
                "
              />
              <span class="countryName">{{ project.country }}</span></span
            >
          </p>
          <p>
            <span class="product-card-description-details-item-title"
              >Target</span
            >
            <span class="product-card-description-details-item-prc green-color">
              {{
                project.baseCurrency?.symbol
                  ? project.baseCurrency?.symbol + " "
                  : ""
              }}
              {{
                Number(Number(project.target).toFixed(0)).toLocaleString(
                  "en-GB"
                )
              }}</span
            >
          </p>
        </div>
        <div class="product-card-description-details-item">
          <p class="hideToSmall">
            <span class="product-card-description-details-item-title"
              >fundraising Period
            </span>
            <span
              class="product-card-description-details-item-prc green-color"
              >{{ project.fundraisingPeriod }}</span
            >
          </p>
          <p>
            <span class="product-card-description-details-item-title"
              >Raised</span
            >
            <span class="product-card-description-details-item-prc green-color">
              {{
                project.baseCurrency?.symbol
                  ? project.baseCurrency?.symbol + " "
                  : ""
              }}
              {{
                Number(Number(project.totalRaised).toFixed(0)).toLocaleString(
                  "en-GB"
                )
              }}</span
            >
          </p>
        </div>
        <div class="product-card-description-details-item hideToSmall">
          <p>
            <span class="product-card-description-details-item-title"
              >City</span
            >
            <span
              class="product-card-description-details-item-prc green-color"
              >{{ project.city }}</span
            >
          </p>
          <p>
            <span class="product-card-description-details-item-title"
              >Investors</span
            >
            <span
              class="product-card-description-details-item-prc green-color"
              >{{ project.investors }}</span
            >
          </p>
        </div>
      </div>
      <div class="product-button">
        <button class="relsify-button" @click="buyShares(project)">
          <span>CO-OWN</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import "@/mixins";
import { mapActions } from "vuex";
export default {
  props: ["project"],
  computed: {
    countries() {
      return this.$store.state.countries;
    },

    selectedCategory: {
      get() {
        return this.$store.state.selectedCategory;
      },
      set(value) {
        return (this.$store.state.selectedCategory = value);
      },
    },

    countryFlag() {
      let flag = "";
      if (this.countries && this.project) {
        let countryFlag = this.countries.find(
          (flag) =>
            flag.name.toLowerCase() == this.project.country.toLowerCase()
        );
        if (countryFlag) {
          flag = countryFlag.flag;
        }
      }

      return flag;
    },
  },
  methods: {
    ...mapActions(["setCurrentProject", "filterProjects", "setLoginModal"]),

    buyShares: function (project) {
      if (!this.user) {
        this.setLoginModal({
          status: true,
          payload: null,
        });

        return;
      }

      let url = `${this.DASHBOARD_URL}/checkout?id=${project.id}`;
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // console.log(project);
      // console.log(this.user);

      // $router.push({ name: 'ProjectDetails' })
    },

    filterProduct: function (category) {
      if (this.selectedCategory == category) {
        return;
      }
      this.selectedCategory = category;
      let url = `${
        this.RELSIFY_URL
      }/api/projects?page=${1}&limit=${20}&category=${category}`;
      this.filterProjects(url);
    },
  },
};
</script>

<style scoped>
.product-card {
  background: #fff;
  color: #7a7a7a;
  border-radius: 20px;
  /* min-width: 320px !important; */
  width: 100% !important;
  position: relative;
  margin-right: 20px;
  box-shadow: 0px 6px 8px 8px rgba(189, 189, 189, 0.22);
}

.product-card .product-card-img {
  position: relative;
}

.product-card .product-card-img img {
  width: 100%;
  height: 120px;
  border-radius: 20px;
  object-fit: cover;
}

.product-card .product-card-img .product-card-category {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  background: #ffffff;
  color: var(--mainGreenColor);
  border-radius: 20px;
  padding: 5px 12px;
  font-size: 11px;
  margin-top: 12px;
  margin-right: 12px;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.product-card .product-card-img .product-card-category:hover {
  background: var(--mainYellowColor);
  color: #444;
}

.product-card-description-header {
  padding-left: 5px;
  padding-right: 5px;
}

.product-card .product-card-description {
  padding: 20px 14px;
}

.product-card .product-card-description h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: var(--mainGreenColor);
}

.product-card .product-card-description p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140.62%;
  color: #505050;
  margin-bottom: 12px;
}

.product-card .product-card-description p b {
  cursor: pointer;
}

.product-card-description-details-item {
  display: block;
}

.product-card-description-details-item.product-card-location {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.product-card-description-details-item p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-card-description-details-item.product-card-location p {
  justify-content: flex-start;
  align-items: center;
}

.product-card-description-details-item.product-card-location p:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 900 !important;
}

.product-card-description-details-item p img {
  width: 16px;
}

.product-card-description-details-item p span {
  margin-left: 5px;
  font-weight: 500;
}

.product-card-description-details-item p small {
  font-size: 10px;
  margin-top: 5px;
  color: #1f1f1f;
}

.product-card .product-button {
  text-align: center;
  margin-top: 32px;
}

.product-card .product-button button {
  padding-right: 40px;
  padding-left: 40px;
  font-size: 14px;
}

.product-card .product-button button:hover {
  background: transparent;
  border: 1px solid var(--mainYellowColor);
  font-size: 13px;
}

@media screen and (max-width: 1150px) {
  .about-banner {
    padding: 80px 100px;
  }

  .about-banner h4 {
    font-size: 40px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 700px) {
  .hideToSmall,
  .product-card hr {
    display: none !important;
  }
}

@media screen and (max-width: 540px) {
  .product-card {
    border-radius: 10px;
  }

  .product-card .product-card-img .product-card-category {
    padding: 4px 8px;
    font-size: 10px;
  }
  .product-card .product-card-img img {
    width: 100%;
    height: 80px;
    border-radius: 10px;
  }

  .product-card .product-card-description {
    padding: 14px 10px 20px;
  }

  .product-card .product-card-description h3 {
    font-size: 12px;
    line-height: 14px;
  }

  .product-card-description-details-item p span {
    font-size: 11px;
  }

  .product-card .product-card-description .product-card-description-header h3 {
    margin-bottom: 20px;
  }

  .product-card .product-card-description .product-card-description-header p,
  .product-card .product-card-description .countryName {
    display: none;
  }

  .product-card-description-details-item-prc img {
    box-shadow: 0px 6px 8px 8px rgba(189, 189, 189, 0.22);
  }

  .product-card .product-button button {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 11px;
    width: 100%;
  }
}
</style>
