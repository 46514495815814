import { RELSIFY_URL, DASHBOARD_URL, WEBSITE_URL } from "../config";

/* eslint-disable */
export default {
  // STAGGING
  RELSIFY_URL,
  DASHBOARD_URL,
  WEBSITE_URL,

  // DASHBOARD_URL: "http://localhost:8084",
  // WEBSITE_URL: "http://localhost:8086",
  token: localStorage.getItem("Appoen") || "",
  user: null,
  registrationType: "withEmail",
  showMobileMenu: false,
  isUserLoggedIn: false,
  pageLoading: false,
  modalLoading: false,
  projects: null,
  projectCategory: null,
  selectedCategory: "",
  projectCurrentPage: 1,
  projectTotalPage: 2,

  selectedCategory: "",
  searchText: "",
  currentPageName: "",

  countries: null,
  states: null,
  countryDialCode: null,
  countryFlag: null,
  dialCodeSelected: null,
  faqs: null,

  verifyPinCodeModal: {
    status: false,
    title: "",
    desc: "",
    redirectUrl: null,
    type: "",
    payload: null,
  },

  alertpopUp: {
    status: false,
    title: "",
    des: "",
    type: "",
    payload: null,
  },

  loginModal: {
    status: false,
    payload: null,
  },
};
