<template>
  <!-- <div> -->
    <div ref="product_slide" class="productSlide product_slide">
      <transition-group v-if="isVisible" appear mode="out-in" @before-enter="beforeEnter" @enter="enter">
          <ProductItem
          v-for="(project, index)  in projects"
          :data-index="index + 1"
          :key="project.id"
          :project="project"
        />
      </transition-group>
    </div>
    <div class="text-center">
      <ProjectInfinitescrolltrigger />
    </div>
  <!-- </div> -->
</template>

<script>
import { gsap } from 'gsap'
import { ref } from 'vue';
import ProductItem from "./ProductItem.vue";
import ProjectInfinitescrolltrigger from "./ProjectInfinitescrolltrigger.vue";
export default {
  props: ["projects"],
  components: {
    ProductItem,
    ProjectInfinitescrolltrigger,
  },
  setup() {
    const isVisible = ref(false)
    const product_slide = ref(null)

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('product_slide')) {
          isVisible.value = true
          observer.unobserve(entry.target)
        }
      }
    }

    const product_slide_observer = new IntersectionObserver(handle_intersection);

    return {
      isVisible,

      product_slide,

      product_slide_observer,
    }
  },
  methods: {
    beforeEnter (el) {
      el.style.opacity = 0
      el.style.transform = 'translate3d(0, 100%, 0)'
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: .5,
        onComplete: done,
        delay: el.dataset.index * 0.1
      })
    },
    isScrolledIntoView(el) {
      const rect = el.getBoundingClientRect()
      const elemTop = rect.top
      const elemBottom = rect.bottom
      this.isVisible = elemTop < window.innerHeight && elemBottom >= 0
      return this.isVisible
    },
    handleScroll() {
      /* if scrolled into view */
      this.isScrolledIntoView(this.productSlide)
    }
  },
  mounted() {
    this.product_slide_observer.observe(this.product_slide);
  }
};
</script>

<style scoped></style>
