<template>
  <div class="alert-modal alertPOP" v-if="alertpopUp.status">
    <div class="alert-modal-overlay" @click="closeAlertPopup"></div>
    <div class="alert-modal-card vivify popInBottom">
      <div class="close-alert-button">
        <i class="bx bx-x" id="closeAlertModal" @click="closeAlertPopup"></i>
      </div>

      <div class="alert-modal-body">
        <div
          class="alert-modal-icon"
          :class="alertpopUp.type == 'error' && 'error'"
        >
          <i class="bx bx-check" v-if="alertpopUp.type !== 'error'"></i>
          <i class="bx bx-x" v-if="alertpopUp.type == 'error'"></i>
        </div>
        <h4>{{ alertpopUp.title }}</h4>
        <p v-if="alertpopUp.des">
          {{ alertpopUp.des }}
        </p>

        <div class="alert-modal-button">
          <button class="relsify-button" @click="closeAlertPopup">Okay</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["alertpopUp"]),
  },

  methods: {},
  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped></style>
