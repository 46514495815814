<template>
  <div>
    <HomeBanner />
    <HomeSolidConvergence />
    <ExploreDecideInvest />
    <ProductContainer />
    <ManageAssets />
    <ReachWithin />
    <TrackAndWithdrawIncomeBanner />
  </div>
</template>

<script>
import HomeBanner from "../components/extras/banners/HomeBanner.vue";
import ExploreDecideInvest from "../components/extras/ExploreDecideInvest.vue";
import HomeSolidConvergence from "../components/extras/HomeSolidConvergence.vue";
import ProductContainer from "../components/extras/products/ProductContainer.vue";
import ManageAssets from "../components/extras/ManageAssets.vue";
import ReachWithin from "../components/extras/ReachWithin.vue";
import TrackAndWithdrawIncomeBanner from "../components/extras/banners/TrackAndWithdrawIncomeBanner.vue";
import { mapActions } from "vuex";
export default {
  components: {
    HomeBanner,
    ExploreDecideInvest,
    HomeSolidConvergence,
    ProductContainer,
    ManageAssets,
    ReachWithin,
    TrackAndWithdrawIncomeBanner,
  },

  data() {
    return {
      title1: "Relsify Privacy Policy",
      title2: "Global Privacy Policy",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.$store.state.searchText = "";
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
