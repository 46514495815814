import { mapActions, mapGetters } from "vuex";
// import db from "@/components/extras/firebaseInit";
import $ from "jquery";
import Localbase from "localbase";
let db = new Localbase("db");
db.config.debug = false;

const myMixin = {
  computed: {
    ...mapGetters([
      "user",
      "token",
      "loginInfo",
      "currentPageName",
      "currentPageType",
      "marketPlaceModal",
      "currentProject",
      "tabProjectClicked",
      "RELSIFY_URL",
      "DASHBOARD_URL",
      "RequestId",
    ]),
  },
  methods: {
    ...mapActions([
      "setSideNotificationModal",
      "setMarketPlaceModal",
      "setVerifyPinCodeModal",
      "setAlertMessageModal",
      "setAlertpopUp",
      "getUserProfile",
      "logoutUserFromAccount",
    ]),

    // VERIFY USER AUTHENTICATION
    verifyUserAuth: function () {
      let token = !!sessionStorage.getItem("Appoen");

      if (token) {
        this.processLogin();
      }
    },

    processLogin: async function () {
      let userData = await db.collection("user").doc("login-user").get();

      if (userData) {
        this.$store.dispatch("login", userData);

        console.log(userData);
        this.globalFunction();
      }
    },

    // GLOBAL JAVASCRIPT FUNCTIONS
    globalFunction: function () {
      this.$store.dispatch("globalFunction");
    },

    // GLOBAL JAVASCRIPT FUNCTIONS
    showSideNotificationModal: function () {
      this.setSideNotificationModal(true);
    },

    closeSideNotificationModal: function () {
      this.setSideNotificationModal(false);
    },

    showMarketPlace: function () {
      this.setMarketPlaceModal(true);
      document.getElementById("app").scrollIntoView();
    },

    closeMarketPlace: function () {
      this.setMarketPlaceModal(false);
    },

    closeVerifyPinCode: function () {
      this.setVerifyPinCodeModal({
        status: false,
        title: "",
        redirectUrl: null,
        type: "",
        payload: null,
      });
    },

    closeAlertMessageModal: function () {
      this.setAlertMessageModal({
        status: false,
        title: "",
        des: "",
        button1Text: "",
        button1Url: "",
        button2: true,
        button2Text: "",
        button2Url: "",
        type: "",
        payload: null,
      });
    },

    closeAlertPopup: function () {
      this.setAlertpopUp({
        status: false,
        title: "",
        des: "",
        type: "",
        payload: null,
      });
    },

    inputCodeNext: function () {
      $(document).on("keyup", ".instructionMessageInputCodes", function (e) {
        var target = e.srcElement || e.target;
        var valuee = e.target.value;
        // var allValue = "";

        var regex = /^[a-zA-Z]+$/;

        if (valuee.match(regex)) {
          e.target.value = "";
        } else {
          var maxLength = parseInt(target.attributes["maxlength"].value, 10);
          var myLength = target.value.length;
          if (myLength >= maxLength) {
            var next = target;

            // eslint-disable-next-line no-cond-assign
            while ((next = next.nextElementSibling)) {
              // console.log(next);
              // allValue = allValue + target.value;
              // console.log(allValue);
              if (next == null) break;
              if (next.tagName.toLowerCase() === "input") {
                next.focus();
                break;
              }
            }
          }
          // Move to previous field if empty (user pressed backspace)
          else if (myLength === 0) {
            var previous = target;
            // eslint-disable-next-line no-cond-assign
            while ((previous = previous.previousElementSibling)) {
              if (previous == null) break;
              if (previous.tagName.toLowerCase() === "input") {
                previous.focus();
                break;
              }
            }
          }
        }
      });
    },

    downloadFile(url, fileName) {
      // var save = document.createElement("a");
      // save.href = url;
      // save.download = fileName;
      // save.target = "_blank";
      // document.body.appendChild(save);
      // save.click();
      // document.body.removeChild(save);

      // let newfileName = fileName + new Date();
      // var a = document.createElement("a");

      // a.setAttribute("href", url);
      // a.setAttribute("download", newfileName);
      // a.setAttribute("target", "_blank");

      // a.style.visibility = "hidden";
      // document.body.appendChild(a);
      // a.click();

      // setTimeout(() => {
      //   document.body.removeChild(a);
      // }, 3000);

      fileName;

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: this.token,
        },
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        });

      // this.axios({
      //   url: url,
      //   method: "GET",
      //   responseType: "blob",
      // }).then((response) => {
      //   console.log(response.data);
      //   var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      //   var a = document.createElement("a");
      //   a.href = fileURL;

      //   let imageName = "image" + new Date() + ".jpg";

      //   a.setAttribute("download", imageName);

      //   document.body.appendChild(a);

      //   a.click();
      // });
    },

    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    addComma(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    removeTimeZone(datestring) {
      let finalDate = "";

      if (datestring.split("+").length > 1) {
        let b = datestring.split("T");
        finalDate = b[0];
      } else {
        let b = datestring.split("T");

        if (b.length > 1) {
          b.pop();
          finalDate = b.join("-");
        }
      }

      return finalDate;
    },

    validEmail: function (email) {
      var emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      return emailReg.test(email);
    },

    geCurrentYear: function () {
      var d = new Date();
      return d.getFullYear();
    },

    getTimeAgo: function (date) {
      var currDate = new Date();
      var diffMs = currDate.getTime() - new Date(date).getTime();
      var sec = diffMs / 1000;
      if (sec < 60)
        return (
          parseInt(sec) + " second" + (parseInt(sec) > 1 ? "s" : "") + " ago"
        );
      var min = sec / 60;
      if (min < 60)
        return (
          parseInt(min) + " minute" + (parseInt(min) > 1 ? "s" : "") + " ago"
        );
      var h = min / 60;
      if (h < 24)
        return parseInt(h) + " hour" + (parseInt(h) > 1 ? "s" : "") + " ago";
      var d = h / 24;
      if (d < 30)
        return parseInt(d) + " day" + (parseInt(d) > 1 ? "s" : "") + " ago";
      var m = d / 30;
      if (m < 12)
        return parseInt(m) + " month" + (parseInt(m) > 1 ? "s" : "") + " ago";
      var y = m / 12;
      return parseInt(y) + " year" + (parseInt(y) > 1 ? "s" : "") + " ago";
    },

    removeUnderScoreFromString: function (str) {
      var i,
        frags = str.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toLowerCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    removeDashFromString: function (str) {
      var i,
        frags = str.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    removeCamelCases: function (str) {
      return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    },

    kFormatter: function (num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    formateDateAndTime: function (date) {
      var d = new Date(date);
      var hours = d.getHours();
      var minutes = d.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      let returnedDate;

      returnedDate =
        d.getDate() +
        "/" +
        (d.getMonth() + 1) +
        "/" +
        d.getFullYear() +
        "  " +
        strTime;

      return returnedDate;
    },

    formateDateByName: function (newDate) {
      const d = new Date(newDate);
      const year = d.getFullYear(); // 2019
      const date = d.getDate();
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const dayIndex = d.getDay();
      const dayName = days[dayIndex];

      const monthIndex = d.getMonth();
      const monthName = months[monthIndex];

      const formatted = `${dayName}, ${date} ${monthName} ${year}`;

      return formatted;
    },

    formateDateAndTimeByName: function (newDate) {
      const d = new Date(newDate);
      const year = d.getFullYear(); // 2019
      const date = d.getDate();
      const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

      const months = [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      var hours = d.getHours();
      var minutes = d.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;

      const dayIndex = d.getDay();
      const dayName = days[dayIndex];

      const monthIndex = d.getMonth();
      const monthName = months[monthIndex];

      const formatted = `${dayName}, ${date} ${monthName} ${year}, ${strTime}`;

      return formatted;
    },

    shuffleArray: function (array) {
      var ctr = array.length,
        temp,
        index;
      while (ctr > 0) {
        index = Math.floor(Math.random() * ctr);
        ctr--;
        temp = array[ctr];
        array[ctr] = array[index];
        array[index] = temp;
      }
      return array;
    },

    logoutUser: function () {
      this.logoutUserFromAccount();
    },
  },
};

export default myMixin;
