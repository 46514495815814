<template>
  <div class="alert-modal verifypinModal" v-if="verifyPinCodeModal.status">
    <div class="alert-modal-overlay" @click="closeVerifyPinCode"></div>
    <ModalLoading />
    <div class="alert-modal-card vivify popInBottom">
      <div class="close-alert-button">
        <i class="bx bx-x" id="closeAlertModal" @click="closeVerifyPinCode"></i>
      </div>

      <div class="alert-modal-body">
        <h5>{{ verifyPinCodeModal.title }}</h5>
        <p>{{ verifyPinCodeModal.desc }}</p>

        <div class="instructionMessageInputCodes">
          <input
            type="text"
            maxlength="1"
            ref="emailCode1"
            class="emailCode"
            id="emailCode1"
            data-next="2"
            data-prev="1"
          />
          <input
            type="text"
            maxlength="1"
            ref="emailCode2"
            class="emailCode"
            id="emailCode2"
            data-next="3"
            data-prev="2"
          />
          <input
            type="text"
            maxlength="1"
            ref="emailCode3"
            class="emailCode"
            id="emailCode3"
            data-next="4"
            data-prev="3"
          />
          <input
            type="text"
            maxlength="1"
            ref="emailCode4"
            class="emailCode"
            data-next="5"
            data-prev="4"
            id="emailCode4"
          />
          <input
            type="text"
            maxlength="1"
            ref="emailCode5"
            class="emailCode"
            data-next="6"
            id="emailCode5"
          />
          <input
            type="text"
            maxlength="1"
            class="emailCode"
            data-next="6"
            ref="emailCode6"
            id="emailCode6"
            @input="checkCode"
          />
        </div>

        <div class="text-center resendCode">
          <!-- <a href="javascript:;" @click="resendCode" class="green-color"
            >Resend code</a
          > -->
        </div>

        <div class="alert-modal-button">
          <button class="relsify-button green-button">Verify</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters, mapActions } from "vuex";
import ModalLoading from "./ModalLoading.vue";

// var createHost = require('cross-domain-storage/host');
// var createGuest = require('cross-domain-storage/guest');
// var storageHost = createHost([
//     {
//         origin: 'https://home.relsify.com/',
//         allowedMethods: ['get', 'set', 'remove'],
//     },
//     {
//         origin: 'https://app.relsify.com/',
//         allowedMethods: ['get'],
//     },
// ]);
export default {
  components: {
    ModalLoading,
  },
  computed: {
    ...mapGetters(["verifyPinCodeModal"]),
    sideNotificationModal() {
      return this.$store.state.sideNotificationModal;
    },
  },

  data() {
    return {
      modalLoading: false,
    };
  },

  methods: {
    ...mapActions([
      "setModalLoading",
      "setAlertpopUp",
      "getUserProfile",
      "saveLoginDetails",
      "setLoginModal",
    ]),
    checkCode() {
      let allValues =
        this.$refs.emailCode1.value +
        this.$refs.emailCode2.value +
        this.$refs.emailCode3.value +
        this.$refs.emailCode4.value +
        this.$refs.emailCode5.value +
        this.$refs.emailCode6.value;

      if (allValues.length == 6) {
        // console.log(allValues);
        this.setModalLoading(true);
        this.processAction(allValues);
      }
    },

    processAction: function (value) {
      if (this.verifyPinCodeModal.type == "LOGIN") {
        this.verifyLogin(value, this.verifyPinCodeModal.payload);
        return;
      }
    },

    verifyLogin: function (value, payload) {
      let secretTokenId = payload.secretTokenId;
      let email = payload.email;
      let phone = payload.phoneNumber;
      let token = value;

      let url = `${this.RELSIFY_URL}/api/verify-login`;
      let newPayload;

      if (phone) {
        newPayload = {
          secretTokenId: secretTokenId,
          secretTokenCode: token,
          phoneNumber: phone,
        };
      } else {
        newPayload = {
          secretTokenId: secretTokenId,
          secretTokenCode: token,
          email: email,
        };
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newPayload),
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            this.saveLoginDetails();
            this.clearFormFields();

            localStorage.setItem("Appoen", data.meta.token);
            localStorage.setItem("actForms", data.data.id);

            this.axios.defaults.headers.common["Authorization"] =
              data.meta.token;
            this.setModalLoading(false);
            this.setLoginModal({
              status: false,
              payload: null,
            });

            this.setAlertpopUp({
              status: false,
              title: "Login Succesful",
              des: "",
              type: "success",
              payload: null,
            });
            this.closeVerifyPinCode();
            this.getUserProfile(data.meta.token);
            let dashboardUrl = this.$store.state.DASHBOARD_URL;

            var createGuest = require("cross-domain-storage/guest");
            var bazStorage = createGuest(dashboardUrl);

            bazStorage.set("Appoen", data.meta.token, function (error, data) {
              // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
              error, data;
            });
            bazStorage.set("actForms", data.meta.id, function (error, data) {
              // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
              error;
              data;
            });

            setTimeout(() => {
              this.$store.dispatch("globalFunction");
            }, 300);
          } else {
            this.setModalLoading(false);
            this.setAlertpopUp({
              status: true,
              title: "Login Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
            console.log(data);
            this.clearFormFields();
          }
        })
        .catch((error) => {
          this.setModalLoading(false);
          this.clearFormFields();
          console.log(error);
        });
    },

    clearFormFields: function () {
      this.$refs.emailCode1.value = "";
      this.$refs.emailCode2.value = "";
      this.$refs.emailCode3.value = "";
      this.$refs.emailCode4.value = "";
      this.$refs.emailCode5.value = "";
      this.$refs.emailCode6.value = "";
    },
  },
  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped>
.alert-modal-card {
  width: 480px;
  height: auto;
}

.instructionMessageInputCodes {
  width: 100%;
}

.instructionMessageInputCodes input {
  width: 50px;
  height: 39px;
  background: rgba(24, 133, 111, 0.05);
  border-radius: 171.051px;
}

.alert-modal-body h5 {
  text-align: center;
  margin-top: 40px;
  font-weight: 500;
  font-size: 19px;
}

.resendCode {
  margin-top: 30px;
  margin-bottom: 20px;
}

.resendCode a {
  font-weight: bold;
  text-decoration: none;
  font-size: 13px;
}

.modalLoading {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(231, 231, 231, 0.73);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  z-index: 9980 !important;
  border-radius: 30px;
}

@media screen and (max-width: 580px) {
  .alert-modal-card {
    width: 98%;
  }
}
</style>
