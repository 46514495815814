<template>
  <div
    class="products_categories"
    v-if="projectCategory && projectCategory.length > 0"
  >
    <div
      class="products_categories_item"
      :class="selectedCategory == '' && 'active'"
      @click="filterProduct('')"
    >
      <img src="@/assets/img/vectors/building.png" alt="" />
      <p>All Projects</p>
    </div>
    <div
      class="products_categories_item"
      v-for="(prodct, index) in projectCategory"
      :key="index"
      href="javascript:;"
      :class="prodct.category == selectedCategory && 'active'"
      @click="filterProduct(prodct.category)"
      style="text-transform: capitalize"
    >
      <img
        v-if="prodct.category.toLowerCase() == 'residential'"
        src="@/assets/img/vectors/home-p.png"
        alt=""
      />
      <img
        v-if="prodct.category.toLowerCase() == 'commercial'"
        src="@/assets/img/vectors/building.png"
        alt=""
      />

      <img
        v-if="prodct.category.toLowerCase() == 'industrial'"
        src="@/assets/img/vectors/boat-p.png"
        alt=""
      />
      <img
        v-if="prodct.category.toLowerCase() == 'agriculture'"
        src="@/assets/img/vectors/house-river.png"
        alt=""
      />
      <p>{{ prodct.category }}</p>
    </div>
    <!-- <div class="products_categories_item">
          <img src="@/assets/img/vectors/building.png" alt="" />
          <p>Commercial</p>
        </div>
        <div class="products_categories_item">
          <img src="@/assets/img/vectors/boat-p.png" alt="" />
          <p>Industrial</p>
        </div>
        <div class="products_categories_item">
          <img src="@/assets/img/vectors/house-river.png" alt="" />
          <p>Agriculture</p>
        </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["projects", "projectCategory"]),
    selectedCategory: {
      get() {
        return this.$store.state.selectedCategory;
      },
      set(value) {
        return (this.$store.state.selectedCategory = value);
      },
    },
    searchText: {
      get() {
        return this.$store.state.searchText;
      },
      set(value) {
        return (this.$store.state.searchText = value);
      },
    },
  },

  methods: {
    ...mapActions([
      "setCurrentProject",
      "filterProjects",
      "searchProject",
      "setPageLoading",
    ]),

    filterProduct: function (category) {
      if (this.selectedCategory == category) {
        return;
      }
      this.selectedCategory = category;
      let url = `${
        this.RELSIFY_URL
      }/api/projects?page=${1}&limit=${20}&category=${category}`;
      this.filterProjects(url);
    },
  },
};
</script>

<style scoped>
.products_categories {
  width: 700px;
  margin: 50px auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.products_categories_item {
  text-align: center;
  margin: 0px 18px;
  cursor: pointer;
}

.products_categories_item:first-child {
  margin-left: 0px;
}

.products_categories_item:last-child {
  margin-right: 0px;
}

.products_categories_item img {
  width: 73px;
  background: #ffffff;
  border: 1px solid var(--mainGreenColor);
  border-radius: 18px;
  padding: 12px 16px;
  transition: all 300ms ease;
}

.products_categories_item img:hover {
  background: var(--mainLightGreenColor);
}

.products_categories_item.active img {
  background: white;
  border-width: 6px;
}

.products_categories_item p {
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--mainGreenColor);
  white-space: nowrap;
  font-size: 14px;
}

@media screen and (max-width: 1250px) {
  .products_categories {
    width: 680px;
    margin: 50px auto 30px;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 750px) {
  .products_categories {
    width: 100%;
    margin: 50px auto 30px;
  }

  .products_categories_item img {
    width: 60px;
    padding: 12px 16px;
  }

  .products_categories_item p {
    margin-top: 10px;
    font-size: 13px;
  }
}

@media screen and (max-width: 570px) {
  .products_categories {
    width: 100%;
    margin: 50px auto 30px;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    padding-left: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .products_categories::-webkit-scrollbar {
    width: 0px;
  }

  .products_categories_item {
    width: 100px;
  }
  .products_categories_item img {
    width: 48px;
    padding: 10px;
  }
  .products_categories_item p {
    white-space: nowrap;
  }
}

@media screen and (max-width: 480px) {
  .products_categories_item {
    width: 90px;
  }
  .products_categories_item img {
    width: 45px;
    padding: 10px;
    border-radius: 10px;
  }

  .products_categories_item p {
    font-size: 12px;
  }
}
</style>
