<template>
  <div class="projects-search">
    <div class="relsify-search">
      <i class="bx bx-search-alt-2"></i>
      <input
        type="search"
        v-model="searchText"
        placeholder="e.g residential building in lekki lagos"
      />
    </div>
    <div class="product_search_filter">
      <div class="search_filter" @click="searchAllProjects">
        <!-- <img src="@/assets/img/vectors/filter_list.png" alt="" /> -->
        <!-- <span>Filters</span> -->
        <span style="margin-left: 0px">Search</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["projects", "projectCategory"]),
    selectedCategory: {
      get() {
        return this.$store.state.selectedCategory;
      },
      set(value) {
        return (this.$store.state.selectedCategory = value);
      },
    },
    searchText: {
      get() {
        return this.$store.state.searchText;
      },
      set(value) {
        return (this.$store.state.searchText = value);
      },
    },
  },

  methods: {
    ...mapActions([
      "setCurrentProject",
      "filterProjects",
      "searchProject",
      "setPageLoading",
    ]),

    searchAllProjects: function () {
      if (!this.searchText) {
        return;
      }

      this.setPageLoading(true);

      let payload = {
        search: this.searchText,
        country: "",
        state: "",
        city: "",
        status: "",
      };

      this.searchProject(payload)
        .then((data) => {
          if (!data.data.error) {
            this.setPageLoading(false);
          } else {
            this.setPageLoading(false);
          }
        })
        .catch((error) => {
          error;
          this.setPageLoading(false);
        });
    },
  },
};
</script>

<style scoped>
.projects-search {
  width: 700px;
  margin: 30px auto;
  display: grid;
  grid-template-columns: 1fr 200px;
  align-items: center;
  margin-right: 260px;
}

.relsify-search {
  padding-top: 10px;
  padding-bottom: 8px;
}

.relsify-search ::placeholder {
  color: var(--mainGreenColor);
}
.product_search_filter {
  margin-left: 20px;
}

.search_filter {
  background: var(--mainGreenColor);
  width: 120px;
  padding: 8px 0px;
  text-align: center;
  border-radius: 40px;
  color: #fff;
  cursor: pointer;
}

.search_filter img {
  width: 30px;
}

.search_filter span {
  margin-left: 10px;
}

@media screen and (max-width: 1250px) {
  .projects-search {
    width: 650px;
    margin: 30px auto;
    grid-template-columns: 1fr 200px;
  }
}

@media screen and (max-width: 750px) {
  .projects-search {
    width: 100%;
    margin: 30px auto;
    grid-template-columns: 1fr 130px;
  }

  .search_filter {
    width: auto;
    padding: 8px 0px;
  }

  .search_filter img {
    width: 25px;
  }
}

@media screen and (max-width: 570px) {
  .projects-search {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 480px) {
  .projects-search {
    grid-template-columns: 1fr auto;
    padding-left: 13px;
    padding-right: 10px;
  }

  .relsify-search {
    padding-top: 7px;
    padding-bottom: 6px;
  }
  .product_search_filter {
    margin-left: 5px;
  }

  .search_filter {
    width: 100%;
    padding: 4px 15px;
  }

  .search_filter img {
    width: 20px;
  }
  .search_filter span {
    font-size: 12px;
  }
}
</style>
