<template>
  <div class="reachWithin">
    <h4>It's Right Within Your <span class="text-yellow">Reach</span></h4>
    <div class="reachWithin_grid">
      <div ref="reach_within_card_1" class="reachWithin_grid_item reach_within_card_1"
      style="overflow: hidden;"
      >
        <img
          src="@/assets/img/vectors/reach-1.png"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Low Entry Barrier</h5>
        <p>
          Middle-class and low income earners are factored into real estate
          investment opportunities and can enter with as low as $1
        </p>
      </div>
      <div ref="reach_within_card_2" class="reachWithin_grid_item reach_within_card_2">
        <img
          src="@/assets/img/vectors/reach-2.png"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Global Access</h5>
        <p>
          Developers can welcome foreign investment, finish up projects in far
          shorter time, offer luxury and cut unnecessary overhead costs.
        </p>
      </div>
      <div ref="reach_within_card_3" class="reachWithin_grid_item reach_within_card_3">
        <img
          src="@/assets/img/vectors/reach-3.png"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>10x Efficiency</h5>
        <p>
          Traditional real estate process is shortentened to only 1-5mins with
          records automated and accessible even on reflex.
        </p>
      </div>
      <div ref="reach_within_card_4" class="reachWithin_grid_item reach_within_card_4">
        <img
          src="@/assets/img/vectors/reach-4.png"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Sufficient Liquidity</h5>
        <p>
          Properties invested in could easily be sold off as the digitized
          marketplace is always ready with liquidity.
        </p>
      </div>
      <div ref="reach_within_card_5" class="reachWithin_grid_item reach_within_card_5">
        <img
          src="@/assets/img/vectors/reach-5.png"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Perfect Hedge</h5>
        <p>
          Escape the terror of a bear market and extreme volatility and earn
          well-projected returns as a Crypto asset owner.
        </p>
      </div>
      <div ref="reach_within_card_6" class="reachWithin_grid_item reach_within_card_6">
        <img
          src="@/assets/img/vectors/reach-6.png"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Low Cost</h5>
        <p>
          Real estate market transaction cost cut to only $1 or less
          irrespective of the value of a property..
        </p>
      </div>
      <div ref="reach_within_card_7" class="reachWithin_grid_item reach_within_card_7">
        <img
          src="@/assets/img/vectors/reach-7.png"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Ownership Transfer</h5>
        <p>
          Peer-to-peer transfer of property ownership right within just
          10-20secs without any hassle whatsoever.
        </p>
      </div>
      <div ref="reach_within_card_8" class="reachWithin_grid_item reach_within_card_8">
        <img
          src="@/assets/img/vectors/reach-8.png"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Cross-border Opportunity</h5>
        <p>
          Explore the real estate market difference across several African
          economies and benefit maximum returns on investment.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const reach_within_card_1 = ref(null)
    const reach_within_card_2 = ref(null)
    const reach_within_card_3 = ref(null)
    const reach_within_card_4 = ref(null)
    const reach_within_card_5 = ref(null)
    const reach_within_card_6 = ref(null)
    const reach_within_card_7 = ref(null)
    const reach_within_card_8 = ref(null)

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if (
            entry.target.classList.contains('reach_within_card_1') ||
            entry.target.classList.contains('reach_within_card_2') ||
            entry.target.classList.contains('reach_within_card_3') ||
            entry.target.classList.contains('reach_within_card_4') ||
            entry.target.classList.contains('reach_within_card_5') ||
            entry.target.classList.contains('reach_within_card_6') ||
            entry.target.classList.contains('reach_within_card_7') ||
            entry.target.classList.contains('reach_within_card_8')
          ) {
          entry.target.classList.add('zoom-in')
          observer.unobserve(entry.target)
        }
      }
    }

    const reach_within_card_1_observer = new IntersectionObserver(handle_intersection)
    const reach_within_card_2_observer = new IntersectionObserver(handle_intersection)
    const reach_within_card_3_observer = new IntersectionObserver(handle_intersection)
    const reach_within_card_4_observer = new IntersectionObserver(handle_intersection)
    const reach_within_card_5_observer = new IntersectionObserver(handle_intersection)
    const reach_within_card_6_observer = new IntersectionObserver(handle_intersection)
    const reach_within_card_7_observer = new IntersectionObserver(handle_intersection)
    const reach_within_card_8_observer = new IntersectionObserver(handle_intersection)

    return {
      reach_within_card_1,
      reach_within_card_2,
      reach_within_card_3,
      reach_within_card_4,
      reach_within_card_5,
      reach_within_card_6,
      reach_within_card_7,
      reach_within_card_8,

      reach_within_card_1_observer,
      reach_within_card_2_observer,
      reach_within_card_3_observer,
      reach_within_card_4_observer,
      reach_within_card_5_observer,
      reach_within_card_6_observer,
      reach_within_card_7_observer,
      reach_within_card_8_observer
    }
  },
  mounted() {
    this.reach_within_card_1_observer.observe(this.reach_within_card_1)
    this.reach_within_card_2_observer.observe(this.reach_within_card_2)
    this.reach_within_card_3_observer.observe(this.reach_within_card_3)
    this.reach_within_card_4_observer.observe(this.reach_within_card_4)
    this.reach_within_card_5_observer.observe(this.reach_within_card_5)
    this.reach_within_card_6_observer.observe(this.reach_within_card_6)
    this.reach_within_card_7_observer.observe(this.reach_within_card_7)
    this.reach_within_card_8_observer.observe(this.reach_within_card_8)
  }
};
</script>

<style scoped>
.reachWithin {
  background: #fff;
  width: 100%;
  padding: 90px 100px;
  overflow: hidden;
}

.reachWithin h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #181818;
  margin-bottom: 90px;
}

.reachWithin_grid {
  width: 100%;
  margin: 60px auto 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px 30px;
  align-items: center;
}

.reachWithin_grid_item {
  background: rgba(24, 133, 111, 0.05);
  border-radius: 14.0415px;
  padding: 20px 18px;
}

.reachWithin_grid_item:nth-of-type(even) {
  background: var(--mainLightYellowColor) !important;
}

.reachWithin_grid_item h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: #383838;
  margin-top: 19px;
  margin-bottom: 25px;
}

.reachWithin_grid_item p {
  font-style: normal;
  font-weight: normal;
  font-size: 14.9776px;
  line-height: 21px;
  color: #383838;
}

@media screen and (max-width: 1300px) {
  .reachWithin {
    width: 100%;
    padding: 90px 30px;
  }

  .reachWithin h4 {
    font-size: 39px;
  }

  .reachWithin_grid {
    /* width: 80%; */
    margin: 60px auto 50px;
    gap: 40px 20px;
  }

  .reachWithin_grid_item h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .reachWithin_grid_item h5 {
    font-size: 15px;
  }

  .reachWithin_grid_item p {
    font-size: 13px;
  }
}

@media screen and (max-width: 1098px) {
  .reachWithin_grid {
    width: 100%;
    margin: 60px auto 50px;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 20px;
  }
}

@media screen and (max-width: 950px) {
  .reachWithin {
    padding: 90px 25px;
  }
}

@media screen and (max-width: 870px) {
  .reachWithin {
    padding: 90px 35px;
  }
  .reachWithin h4 {
    font-size: 35px;
  }

  .reachWithin_grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 20px;
  }
}

@media screen and (max-width: 750px) {
  .reachWithin_grid {
    gap: 40px 15px;
  }
}

@media screen and (max-width: 600px) {
  .reachWithin h4 {
    font-size: 30px;
    text-align: center;
  }

  .reachWithin_grid {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 12px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 460px) {
  .reachWithin h4 {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 330px) {
  .reachWithin {
    padding: 60px 19px;
  }

  .reachWithin h4 {
    line-height: 38px;
  }
}
</style>
