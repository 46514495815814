import countries from "../api/countries";

import states from "../api/states";

let Countries = countries.getCountryList();
let States = states.getStateList();

export const SAVE_USER_PROFILE = (state, data) => {
  state.user = data;
  state.isUserLoggedIn = true;
};

export const SET_MOBILE_MENU = (state) => {
  state.showMobileMenu = !state.showMobileMenu;
};

export const SET_PAGE_LOADING = (state, status) => {
  state.pageLoading = status;
};

export const SET_MODAL_LOADING = (state, status) => {
  state.modalLoading = status;
};

export const CLOSE_MOBILE_MENU = (state) => {
  state.showMobileMenu = false;
};

export const SAVE_USER_LOGIN_INFO = (state, data) => {
  state.loginInfo = data;
};

export const SET_CURRENT_PAGE_NAME = (state, data) => {
  state.currentPageName = data;
};

export const LOGOUT = (state) => {
  state.token = "";
  state.isUserLoggedIn = false;
  state.user = null;
};

export const GET_COUNTRIES = (state) => {
  if (state.countries == null) {
    state.countries = Countries;

    let selectedCountry = state.countries.find((flag) => flag.isoCode == "NG");
    if (selectedCountry) {
      state.dialCodeSelected = selectedCountry;
      state.countryFlag = selectedCountry.flag;
      state.countryDialCode = selectedCountry.dialCode;
    }
    // console.log(Countries);
  }
};

export const GET_STATES = (state) => {
  state.states = States;
  // console.log(state.states);
};

export const SAVE_FAQS = (state, faqs) => {
  state.faqs = faqs;
  // console.log(state.states);
};

export const SAVE_PROJECTS = (state, payload) => {
  function eliminateDuplicateCategory(arr) {
    return arr
      .map((e) => e["category"])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((obj) => arr[obj])
      .map((e) => arr[e]);
  }

  if (state.projects == null) {
    state.projectCategory = eliminateDuplicateCategory(payload);
  }

  state.projects = payload;
};

export const SAVE_MORE_PROJECTS = (state, data) => {
  for (let i = 0; i < data.length; i++) {
    state.projects.push(data[i]);
  }
};

export const SET_VERIFY_PIN_CODE_MODAL = (state, payload) => {
  state.verifyPinCodeModal = {
    status: payload.status,
    title: payload.title,
    redirectUrl: payload.redirectUrl,
    type: payload.type,
    desc: payload.desc,
    payload: payload.payload,
  };
};

export const SET_ALERT_POPUP = (state, payload) => {
  state.alertpopUp = {
    status: payload.status,
    title: payload.title,
    des: payload.des,
    type: payload.type,
    payload: payload.payload,
  };
};

export const SET_LOGIN_MODAL = (state, payload) => {
  state.loginModal = {
    status: payload.status,
    payload: payload.payload,
  };
};
