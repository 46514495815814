<template>
  <div class="solidConvergence">
    <h4 class="solidConvergenceHeaderText">An Arena of Solid <span class="text-green">Convergence</span></h4>
    <div class="solidConvergence_grid">
      <div ref="solid_convergence_image" class="solidConvergence_grid_item solid_convergence_image">
        <img src="@/assets/img/solidconv.png" alt="" />
      </div>
      <div ref="solid_convergence_desc" class="solidConvergence_grid_item solid_convergence_desc">
        <p>
          Here is where experienced real estate developers cross paths with
          retail and institutional investors within a seamless win-win
          infrastructure. Just like Real Estate investing meets Blockchain!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const solid_convergence_image = ref(null)
    const solid_convergence_desc = ref(null)

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('solid_convergence_image')) {
          entry.target.classList.add('bounce-in')
          observer.unobserve(entry.target)
        } else if (entry.target.classList.contains('solid_convergence_desc')) {
          entry.target.classList.add('zoom-in')
          observer.unobserve(entry.target)
        }
      }
    }

    const solid_convergence_image_observer = new IntersectionObserver(handle_intersection)
    const solid_convergence_desc_observer = new IntersectionObserver(handle_intersection)

    return {
      solid_convergence_image,
      solid_convergence_image_observer,
      solid_convergence_desc,
      solid_convergence_desc_observer
    }
  },
  mounted() {
    this.solid_convergence_image_observer.observe(this.solid_convergence_image)
    this.solid_convergence_desc_observer.observe(this.solid_convergence_desc)
  }
};
</script>

<style scoped>
.solidConvergence {
  padding: 80px 130px;
  background: #fff;
  overflow: hidden;
  transition: all;
}

.solidConvergence h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 63px;
  color: #000000;
  margin-bottom: 40px;
}

.solidConvergence_grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.solidConvergence_grid .solidConvergence_grid_item:last-child {
  width: 50%;
  margin-left: 120px;
}

.solidConvergence_grid .solidConvergence_grid_item p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 180.62%;
  color: #363636;
}

@media screen and (max-width: 1350px) {
  .solidConvergence {
    padding: 80px 60px;
  }
}

@media screen and (max-width: 1200px) {
  .solidConvergence {
    padding: 80px 60px;
  }

  .solidConvergence h4 {
    font-size: 30px;
  }

  .solidConvergence_grid .solidConvergence_grid_item:last-child {
    width: 80%;
    margin-left: 90px;
  }
}

@media screen and (max-width: 1050px) {
  .solidConvergence_grid .solidConvergence_grid_item p {
    font-size: 18px;
  }

  .solidConvergence_grid .solidConvergence_grid_item:last-child {
    width: 100%;
    margin-left: 90px;
  }
}

@media screen and (max-width: 900px) {
  .solidConvergence {
    padding: 80px 40px;
  }

  .solidConvergence h4 {
    font-size: 26px;
  }
  .solidConvergence_grid .solidConvergence_grid_item:last-child {
    margin-left: 50px;
  }
}

@media screen and (max-width: 769px) {
  .solidConvergence {
    padding: 80px 90px;
  }

  .solidConvergence h4 {
    text-align: center;
  }

  .solidConvergence_grid {
    display: block;
  }

  .solidConvergence_grid .solidConvergence_grid_item:last-child {
    margin-left: 0px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 650px) {
  .solidConvergence {
    padding: 80px 40px;
  }
}

@media screen and (max-width: 550px) {
  .solidConvergence {
    padding: 80px 0px;
  }

  .solidConvergence h4 {
    /* font-size: 40px; */
    line-height: 33px;
  }

  .solidConvergence_grid .solidConvergence_grid_item:last-child {
    padding: 0px 25px;
  }
}

@media screen and (max-width: 430px) {
  .solidConvergence {
    padding: 60px 0px;
  }

  .solidConvergence h4 {
    font-size: 21px;
  }
  .solidConvergence_grid .solidConvergence_grid_item:last-child {
    margin-top: 30px;
  }

  .solidConvergence_grid .solidConvergence_grid_item p {
    font-size: 15px;
  }
}
</style>
